import React from 'react'
import Text from '@embracesbs/component-text'
import { Tenant, SubscriptionWithTenants } from '../../Interfaces/Types'
import { Link } from 'react-router-dom'

interface tenantDetailsProbs {
  tenant?: Tenant
  subscription?: SubscriptionWithTenants | undefined
  onTenantClick: () => void
  onSubscriptionClick: () => void
}

const Breadcrumbs: React.FC<tenantDetailsProbs> = (props) => {
  const linkStyle = {
    cursor: 'pointer'
  }

  return (
    <Text>
      <div>
        <Link to={'/subscriptions'} style={{ textDecoration: 'none' }}>
          <span className='breadcrumb-link' style={linkStyle} onClick={props.onSubscriptionClick}>
            Subscriptions /{' '}
          </span>
        </Link>
        <Link to={`/subscriptions/${props.subscription?.subscription.id}/tenant/${props.tenant?.id}`} style={{ textDecoration: 'none' }}>
          <span className='breadcrumb-link' style={linkStyle} onClick={props.onTenantClick}>
            {props.subscription?.subscription.customerName} /{' '}
          </span>
        </Link>
        <span>{props?.tenant?.tenantDisplayname}</span>
      </div>
    </Text>
  )
}

export default Breadcrumbs
