import React from 'react'
import Container from '@embracesbs/component-container/Container'
import Sidebar2 from '@embracesbs/component-sidebar2/Sidebar2'
import SidebarItem from '@embracesbs/component-sidebaritem/SidebarItem'
import { Link } from 'react-router-dom'

type IconName =
  | 'AppSocial'
  | 'FlagPlain3'
  | 'AccessFile'
  | 'AccountingDocument'
  | 'Activity'
  | 'AddCircleBottom'
  | 'AddCircleLeft'
  | 'AddCircleRight'
  | 'AddCircleTop'
  | 'AddCircle'
  | 'Add'

const menuItems: Array<{
  id: string
  iconName: IconName
  activeIconName: IconName
  title: string
  path: string
}> = [
  {
    id: 'dashboard',
    iconName: 'AppSocial',
    activeIconName: 'AppSocial',
    title: 'Subscriptions',
    path: '/'
  },
  {
    id: 'featureFlag',
    iconName: 'FlagPlain3',
    activeIconName: 'FlagPlain3',
    title: 'feature flag alpha',
    path: '/feature-flags/alpha'
  },
  {
    id: 'featureFlag-stable',
    iconName: 'FlagPlain3',
    activeIconName: 'FlagPlain3',
    title: 'feature flag stable alpha',
    path: '/feature-flags/stable-alpha'
  }
]

const MainMenu: React.FC = () => {
  return (
    <Sidebar2 style={{ overflow: 'visible', zIndex: 2 }}>
      <Container isVertical isFullHeight>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          {menuItems.map((item) => (
            <Link to={item.path} key={item.id} style={{ textDecoration: 'none' }}>
              <SidebarItem
                key={item.id}
                id={item.id}
                title={item.title}
                icon={{
                  iconName: item.iconName
                }}
                spacing={10}
              />
            </Link>
          ))}
        </section>
      </Container>
    </Sidebar2>
  )
}

export default MainMenu
