import Text from '@embracesbs/component-text'
import { useEffect, useState, useRef, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import callApiWithToken from '../../Utilities/ApiService/ApiService'
import Button2 from '@embracesbs/component-button2/Button2'
import { Tenant } from '../../Interfaces/Types'

export default function DeleteTenant() {
  // tenant
  const [tenantError, setTenantError] = useState<Error | null>(null)
  const [tenantLoaded, setTenantLoaded] = useState(false)
  const [tenant, setTenant] = useState<Tenant>()

  // other
  const [refresh, setRefresh] = useState(0)
  const backgrondRefresh = useRef(false)
  let params = useParams()
  let navigate = useNavigate()

  useEffect(() => {

    const abortController = new AbortController()

    if (backgrondRefresh.current) {
      // do background refresh only this request
      backgrondRefresh.current = false
    } else {
      setTenantLoaded(false)
      setTenantError(null)
    }
    fetchTenantDetails(abortController)

    return () => {
      abortController.abort()
    }
  }, [params.tenantId, refresh])

  const fetchTenantDetails = (abortController: AbortController) => {
    callApiWithToken({
      endpoint: `/api/tenant/${params.tenantId}`,
      method: 'GET',
      body: {
        signal: abortController.signal
      }
    })
      .then(function (response) {
        setTenant(response)
        setTenantLoaded(true)
      })
      .catch(function (error) {
        console.log(error)
        if (!abortController.signal.aborted) {
          setTenantError(error)
        }
      })
  }

  useEffect(() => {
    if (tenant && tenant.activeReleaseLock) {
      const timeout = setTimeout(() => {
        backgrondRefresh.current = true
        setRefresh((r) => r + 1)
      }, 5000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [tenant])

  const handleDelete = () => {
    return new Promise((reject) => {
      callApiWithToken({
        endpoint: `/api/tenant/${tenant?.subscriptionId}`,
        method: 'DELETE'
      })
        .then(() => {
          navigate(`/subscription/${tenant?.subscriptionId}/tenant`, { replace: true })
        })
        .catch((error) => {
          console.log(error)
          reject(error.message)
        })
    })
  }

  if (tenantError) {
    return <div>Error: {tenantError.message}</div>
  } else if (!tenantLoaded) {
    return (
      <center>
        <Text type='grow'>Loading</Text>
      </center>
    )
  } else {
    return (
      <div>
        <h1>Tenant {tenant?.tenantDisplayname}</h1>
        <h4>{tenant?.tenantName}</h4>

        {tenant?.deployStatus == 'pendingDelete' && (
          <div>
            <h4 className='alert-heading'>Your are destroying {tenant?.tenantName}</h4>
            <Button2 text='Delete' color='danger' onClick={handleDelete}></Button2>
          </div>
        )}
      </div>
    )
  }
}
