import styled from '@emotion/styled'

export const BoldText = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`

export const ContentText = styled.span`
  font-weight: 400;
`
export const TextContainerHeading = styled.h3`
  font-family: 'F-Default', sans-serif;
  font-size: 22px;
  margin-bottom: 25px;
`

export const TextHeading = styled.h3`
  font-family: 'F-Default', sans-serif;
  padding-top: 20px;
  font-size: 22px;
  margin-bottom: 25px;
`

export const TextContainer = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  border: 0.0625rem solid rgba(25, 27, 31, 0.2);
  font-family: 'F-Default', sans-serif;
  border-radius: 5px;
`

export const InputField = styled.input`
  font-family: 'F-Default', sans-serif;
  font-size: 0.875rem;
  font-weight: 350;
  line-height: 1.7;
  letter-spacing: 0.0075rem;
  border-radius: 0.125rem;
  padding: 0.5rem 0.75rem;
  text-align: left;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background-color: inherit;
  color: #191b1f;
  padding-left: 0.75rem;
  height: 2.5rem;
  border: 0.0625rem solid rgba(25, 27, 31, 0.2);
  border-radius: 0.25rem;
  background-color: #ffffff;
`

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

export const Aside = styled.aside`
  height: 100%;
  z-index: 10;
`

export const Main = styled.main`
  height: 100%;
  background: #f2f2f4;
`
