import React, { createContext, useState, FC, ReactElement, useEffect, ReactNode } from 'react'
import { EmbraceTenantsConfiguration } from '../../Interfaces/Types'

export const ConfigContext = createContext<EmbraceTenantsConfiguration | undefined>(undefined)

interface ConfigProviderProps {
  children: ReactNode
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<EmbraceTenantsConfiguration>()

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        var baseConfigResonse = await fetch('/config.json')
        var baseConfig = await baseConfigResonse.json()
        const response = await fetch(`https://${baseConfig.host}/api/clientappconfig`)
        const data = await response.json()
        const modifiedData = {
          ...data,
          environments: data.environments.split(';'),
          host: baseConfig.host
        }
        setConfig(modifiedData)
      } catch (error) {
        console.error('Failed to fetch config:', error)
      }
    }

    fetchConfig()
  }, [])

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}
