import React, { useEffect } from 'react'
import ListItem from '@embracesbs/component-listitem'
import { useState } from 'react'
import List from '@embracesbs/component-list/List'
import Button2 from '@embracesbs/component-button2/Button2'
import Button from '@embracesbs/component-button'

import TextField from '@embracesbs/component-textfield/TextField'
import { Subscription, SubscriptionWithTenants, Tenant, BreadcrumbsData } from '../../Interfaces/Types'
import Text from '@embracesbs/component-text'
import Loader from '../Loader/Loader'
import Container from '@embracesbs/component-container/Container'
import AddSubscription from './AddSubscription'
import AddTenant from './AddTenant'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const style = {
  maxHeight: '70%',
  height: '100vh',
  overflow: 'auto'
}

interface TenantListProps {
  propData: SubscriptionWithTenants[]
  isLoading: boolean
  onSelected: (Tenant: Tenant) => void
  onUpdated: (subscription: Subscription) => void
  onTenantUpdated: (tenant: Tenant) => void
  breadcrumbsData: BreadcrumbsData
}

const TenantList: React.FC<TenantListProps> = ({ propData, isLoading, onSelected, onUpdated, onTenantUpdated, breadcrumbsData }) => {
  const params = useParams()
  const history = useNavigate()

  const [subscriptionTenants, setSubscriptionTenants] = useState<Tenant[]>([])
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>()
  const [activeItemId, setActiveItemId] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [newSubscriptionOpen, setNewSubscriptionOpen] = useState(false)
  const [newTenantOpen, setNewTenantOpen] = useState(false)

  useEffect(() => {
    if (breadcrumbsData.trigger && breadcrumbsData.data.subscriptionId) {
      handleSelected(breadcrumbsData.data.subscriptionId)
    } else {
      handleBack()
    }
  }, [breadcrumbsData])

  useEffect(() => {
    if (propData && Object.keys(propData).length !== 0) {
      if (params.subscriptionid) {
        handleSelected(params.subscriptionid, true)
      }
      if (params.tenantid) {
        handleSelectedTenant(params.tenantid, true)
      }
    }
  }, [propData])

  const handleBack = () => {
    history(`/subscriptions/`, { replace: true })
    setSearchValue('')
    setSelectedSubscription(undefined)
    setIsSearch(false)
    setSubscriptionTenants([])
  }

  const handleNewSubscription = () => {
    setNewSubscriptionOpen(!newSubscriptionOpen)
  }

  const handleNewSubscriptionCreate = (subscription: Subscription) => {
    onUpdated(subscription)
    handleSelected(subscription.id)
  }

  const handleNewTenant = () => {
    setNewTenantOpen(!newTenantOpen)
  }

  const handleNewTenantCreate = (tenant: Tenant) => {
    onTenantUpdated(tenant)
  }

  const handleSelectedTenant = (id: string, fromParam?: boolean) => {
    const subscription = propData.find((subscriptionWithTenants) => {
      return subscriptionWithTenants.tenants.some((tenant) => {
        return tenant.id === id
      })
    })

    history(`/subscriptions/${subscription?.subscription.id}/tenant/${id}`, { replace: true })
    const tenant = subscriptionTenants.filter((tenant) => {
      return tenant.id === id
    })
    if (tenant[0]) {
      onSelected(tenant[0])
      setActiveItemId(tenant[0].id)
    }
  }

  const handleSelected = (id: string, fromParam?: boolean) => {
    if (!fromParam) {
      history(`/subscriptions/${id}`, { replace: true })
    }

    setActiveItemId(id)

    const subscription = propData.filter((subscription) => {
      return subscription.subscription.id === id
    })

    if (subscription[0]) {
      setSelectedSubscription(subscription[0].subscription)
      setSubscriptionTenants(subscription[0].tenants)
    }
  }

  const onSearch = (cs: string) => {
    setSelectedSubscription(undefined)

    if (cs === '') {
      setIsSearch(false)
      setSubscriptionTenants([])
      return
    }

    setIsSearch(true)

    const combinedTenants: Tenant[] = propData.reduce((accumulator: Tenant[], subscription: SubscriptionWithTenants) => {
      return accumulator.concat(subscription.tenants)
    }, [])

    const filteredTenants = combinedTenants.filter((tenant) => tenant.tenantName.includes(cs))

    setSubscriptionTenants(filteredTenants)
  }

  if (isLoading) {
    return <Loader showLoader={true} ringCollor='lightgrey' viewbox='-50 10 200 50'></Loader>
  }

  const renderList = () => {
    if (subscriptionTenants.length > 0 || isSearch || selectedSubscription != undefined) {
      if (subscriptionTenants.length < 1) {
        return (
          <>
            <Button2 buttonRadius='8px' onClick={handleBack} text='<' variant='subtle' buttonSize='24px' />
            <Text textColor='grey'>No tenants found for subscription {selectedSubscription?.customerName}</Text>
          </>
        )
      }

      return (
        <>
          <Button2
            buttonRadius='8px'
            onClick={handleBack}
            text='<'
            variant='subtle'
            buttonSize='24px'
            style={{ marginLeft: '12px', marginBottom: '12px' }}
          />
          <List ariaLabel='Test List'>
            {subscriptionTenants.map((item) => (
              <ListItem
                id={item.id}
                key={item.id}
                onItemClick={handleSelectedTenant}
                isActive={item.id === activeItemId}
                icon={{
                  iconName: 'DataFileGraph'
                }}
                title={item.tenantName}
                preset='oneLineIcon'
              />
            ))}
          </List>
        </>
      )
    } else {
      return (
        <>
          <List style={style} ariaLabel='Test List'>
            {propData.map((item) => (
              <ListItem
                id={item.subscription.id}
                key={item.subscription.id}
                onItemClick={(activeItemId) => handleSelected(activeItemId)}
                isActive={item.subscription.id === activeItemId}
                icon={{
                  iconName: 'Building1Filled'
                }}
                title={item.subscription.customerName}
                preset='oneLineIcon'
              />
            ))}
          </List>
        </>
      )
    }
  }

  const renderAddButton = () => {
    if (selectedSubscription != undefined) {
      return (
        <Button
          iconName='Add'
          iconSize={20}
          maxWidth='20rem'
          onClick={handleNewTenant}
          text=''
          tooltip='Add Tenant'
          type='submit'
          variant='round'
        />
      )
    } else {
      return (
        <Button
          iconName='Add'
          iconSize={20}
          maxWidth='20rem'
          onClick={handleNewSubscription}
          text=''
          tooltip='Add Subscription'
          type='submit'
          variant='round'
        />
      )
    }
  }

  return (
    <>
      <Container>
        <TextField
          isSearchMode
          placeholder='Search fo a tenant or subscription'
          onChange={(content, test) => onSearch(content.target.value)}
          onClear={() => onSearch('')}
          value={searchValue}
          type='text'
          variant='default'
        />

        {renderAddButton()}
      </Container>
      <br></br>
      <br></br>
      {renderList()}
      <AddSubscription
        OnClosed={handleNewSubscription}
        IsOpen={newSubscriptionOpen}
        OnAdded={(data) => handleNewSubscriptionCreate(data)}
      ></AddSubscription>
      <AddTenant
        OnCreated={(data) => onTenantUpdated(data)}
        Subscription={selectedSubscription}
        OnClosed={handleNewTenant}
        IsOpen={newTenantOpen}
      ></AddTenant>
    </>
  )
}

export default TenantList
