import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeController } from '@embracesbs/helpers'
import { msalInstance } from './Utilities/Auth/MsalIntance'
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { ConfigProvider } from './Components/Config/ConfigContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <MsalProvider instance={msalInstance}>
        <ThemeController>
          <App />
        </ThemeController>
      </MsalProvider>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
