import logo from './logo.svg'
import './App.css'
import Layout from '@embracesbs/component-layout'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import TenantPage from './Pages/TenantPage'
import DeleteTenant from './Components/Tenants/DeleteTenant'
import MainMenu from './Components/MainMenu/MainMenu'
import AuthenticatedRoute from './Components/Auth/AuthenticatedRoute'
import useServiceWorker from './/Components/ServiceWorker/useServiceWorker'
import { useIsAuthenticated } from '@azure/msal-react'
import FeatureFlagPage from './Pages/FeatureFlagPage'
import { Main, Wrapper, Aside } from './Components/StyledComponents/Components'

function App() {
  useServiceWorker()
  const isAuthenticated = useIsAuthenticated()

  return (
    <>
      <Router>
        <Wrapper>
          <Aside>
            <MainMenu />
          </Aside>
          <Layout>
            <Main>
              <Routes>
                <Route path='/' element={<AuthenticatedRoute component={TenantPage} />} />
                <Route path='/subscriptions' element={<AuthenticatedRoute component={TenantPage} />} />
                <Route path='/subscriptions/:subscriptionid' element={<AuthenticatedRoute component={TenantPage} />} />
                <Route path='/subscriptions/:subscriptionid/tenant/:tenantid' element={<AuthenticatedRoute component={TenantPage} />} />
                <Route path='/feature-flags/:segment' element={<AuthenticatedRoute component={FeatureFlagPage} />} />
                <Route path='/tenant/delete/:tenantId' element={<AuthenticatedRoute component={DeleteTenant} />} />
              </Routes>
            </Main>
          </Layout>
        </Wrapper>
      </Router>
    </>
  )
}

export default App
