import { msalInstance } from './MsalIntance'
import { loginRequest } from './Authconfig'

async function getAccessToken() {
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length === 0) throw new Error('No accounts detected.')

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    })
    return response.accessToken
  } catch (error) {
    console.error('Error acquiring access token', error)
    throw error
  }
}

export { getAccessToken }
