import React, { useEffect, useMemo, useState } from 'react'
import Text from '@embracesbs/component-text'
import { Audit, Tenant } from '../../Interfaces/Types'
import Table from '@embracesbs/component-table'
import TableRow from '@embracesbs/component-tablerow'
import { FeatureFlags } from '../../Interfaces/Types'
import callApiWithToken from '../../Utilities/ApiService/ApiService'

interface tenantDetailsProbs {
  tenant?: Tenant
}

const style = {
  maxHeight: '50%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto' // Enables scrolling
}

const AuditLog: React.FC<tenantDetailsProbs> = ({ tenant }) => {
  const [featureFlags, setFeatureFlags] = React.useState<Audit[]>([])
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = React.useState<Boolean>(false)
  useEffect(() => {
    callApiWithToken({
      endpoint: `/api/audit/${tenant?.id}`,
      method: 'GET' // Your request payload
    })
      .then((data) => {
        setFeatureFlags(data)
        setFeatureFlagsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [tenant])

  if (!tenant) {
    return (
      <>
        <Text align='center' textColor='grey'>
          Please select a tenant
        </Text>
      </>
    )
  }

  return (
    <div style={style}>
      <Table>
        {featureFlags.map((item) => (
          <TableRow>
            <Text>{item.startDate}</Text>
            <Text>{item.action.userName}</Text>
            <Text>Tenant Updated</Text>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default AuditLog
